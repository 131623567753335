import { downloadByGet, get, post } from '@/libs/axios'

// eslint-disable-next-line import/prefer-default-export
export const getEnterpriseUersSelect = () => get('/user/GetEnterpriseUsersSelect')
export const GetVerifyCode = () => get('/GetVerifyCode')
export const GetCurrentUserInfo = () => get('/system/user/GetCurrentUserInfo')
export const QueryUserInfo = id => get(`/system/user/QueryUserInfo?id=${id}`)
export const DelUser = params => post('/system/user/DelUser',params)
export const ListUser = params => post('/system/user/ListUser', params)
export const ImportUser = params => post('/system/user/import', params)
export const DownloadUserImportTemplate = () => downloadByGet(`/system/user/import_template?_t=${Date.parse(new Date())}`)
export const ExportUser = () => downloadByGet(`/system/user/export?_t=${Date.parse(new Date())}`)
export const UpdateStatus = params => post('/system/user/UpdateStatus', params)
export const UpdateCurrentUserInfo = params => post('/system/user/UpdateCurrentUserInfo', params)
export const UpdateCurrentUserPassword = params => post('/system/user/UpdateCurrentUserPassword', params)

export const saveOrUpdateUser = params => {
  const submitUrl = params.id ? '/system/user/UpdateUser' : '/system/user/CreateUser'
  return post(submitUrl, params)
}

export const GetPhoneSmsVerifyCode = params => post('/GetPhoneSmsVerifyCode', params)

export const GetDomain = () => get('/system/company/domain')
